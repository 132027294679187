import React from "react";
import "../cards/styles.css";

const CardBeneficios = () => {
  const itens = [
    {
      icon: "/iconb1.png",
      title: "Vagas remotas e híbridas",
    },
    {
      icon: "/iconb2.png",
      title: "Vale transporte (conforme necessidade)",
    },
    {
      icon: "/iconb3.png",
      title: "WellHub (Gympass)",
    },
    {
      icon: "/iconb4.png",
      title: "Seguro de vida",
    },
    {
      icon: "/iconb5.png",
      title: "Day off aniversário",
    },
    {
      icon: "/iconb6.png",
      title: "Férias remuneradas",
    },
  ];

  return (
    <ul className="card--beneficios__div">
      <h2 className="h2-title-2">Benefícios que você vai receber</h2>
      {itens.map((item, index) => (
        <li className="card--beneficios__li" key={index}>
          <img src={item.icon} alt={item.title} />
          <p className="card--beneficios__title">{item.title}</p>
        </li>
      ))}
    </ul>
  );
};

export default CardBeneficios;
