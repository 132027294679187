import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "../Animations/animation.css";
const AnimationSection = ({ children }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 20 },
      }}
      transition={{
        duration: 0.5,
        ease: "easeInOut",
        delayChildren: 0.5,
        staggerChildren: 0.2,
        when: "beforeChildren",
        loop: Infinity,
      }}
      className="section--animation"
    >
      {children}
    </motion.div>
  );
};

export default AnimationSection;
