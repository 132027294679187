import React from "react";
import SocialIcons from "../socialsIcons/socialsIcons";
import "../footer/styles.css";
const Footer = () => {
  return (
    <footer>
      <div className="div-footer">
        <div className="div--logo___footer">
          <img src="/logo.png" alt="" />
        </div>
        <div className="div-Social__footer">
          <SocialIcons />
        </div>
        <p>Círculo 2024</p>
      </div>
    </footer>
  );
};

export default Footer;
