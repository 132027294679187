import { useEffect, useState } from "react";
import "./App.css";
import MetaTags from "./componentes/metaTags";
import Header from "./componentes/header/header";
import SectionInclusao from "./componentes/sections/Inclusao/sectionIclusao";
import SectionPrincipal from "./componentes/sections/Principal/sectionPrincipal";
import SectionSobreNos from "./componentes/sections/SobreNos/sectionSobreNos";
import SectionInclusaoFull from "./componentes/sections/Inclusao/sectionIclusaoFull";
import SectionPrograma from "./componentes/sections/Programa/sectionPrograma";
import PassosPrograma from "./componentes/sections/Programa/sectionPassosPrograma";
import AreasDoPrograma from "./componentes/sections/Programa/sectionAreaPrograma";
import SectionBeneficios from "./componentes/sections/Beneficios/sectionBeneficios";
import Footer from "./componentes/footer/footer";
import AnimationSection from "./componentes/Animations/AnimationComponent";
function App() {
  const [isMobile, setIsMobile] = useState("false");

  const getScreen = () => {
    if (window.innerWidth < 820) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    getScreen();
    window.addEventListener("resize", getScreen);
    return () => window.removeEventListener("resize", getScreen);
  }, []);
  return (
    <body className="App">
      <MetaTags />
      <Header />
      <SectionPrincipal />
      <SectionSobreNos />
      {isMobile ? <SectionInclusao /> : <SectionInclusaoFull />}
      <SectionPrograma />
      <PassosPrograma />
      <AreasDoPrograma />
      <SectionBeneficios />
      <Footer />
    </body>
  );
}

export default App;
