import React from "react";
import "../Beneficios/styles.css";
import CardBeneficios from "../../cards/CardBeneficios";
import CardRequisitos from "../../cards/CardRequisitos";
import AnimationSection from "../../Animations/AnimationComponent";
const SectionBeneficios = () => {
  return (
    <section className="section--beneficios" id="beneficios">
      <AnimationSection>
        <div className="section--beneficios__container">
          <CardRequisitos />
          <CardBeneficios />
        </div>
        <div className="section--bebeficios__div-text">
          <h2 className="div-text__h2">
            Junte-se ao nosso círculo e faça parte de um programa de estágio
            incrível que  impulsiona sua carreira.
          </h2>
          <p className="div-text__p">
            Inscreva-se agora e comece a transformar seu futuro com a gente! Não
            deixe para depois.
            <br />
            <br />
            <span className="div-text__span">Sua jornada começa aqui!</span>
          </p>
        </div>
        <div>
          <a
            className="section--beneficios__button"
            href="https://forms.gle/6rkvf4ZGPyKq3dME6"
          >
            Quero me Inscrever
          </a>
        </div>
      </AnimationSection>
    </section>
  );
};

export default SectionBeneficios;
