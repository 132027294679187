import React from "react";
import "../cards/styles.css";

const CardRequisitos = () => {
  const itens = [
    {
      icon: "/iconr1.png",
      title: "Estar cursando graduação a partir do 2° semestre",
    },
    {
      icon: "/iconr2.png",
      title: "Previsão de formação a partir de Dezembro de 2025",
    },
    {
      icon: "/iconr3.png",
      title: "Disponibilidade para estagiar 30h semanais (6 horas diárias)",
    },
    {
      icon: "/iconr4.png",
      title: "Ser atento, responsável e ágil",
    },
    {
      icon: "/iconr5.png",
      title: "E, claro, ter vontade de aprender",
    },
  ];

  return (
    <ul className="card--Requisitos__div">
      <h2 className="h2-title">O que você precisa para entrar na Círculo</h2>
      {itens.map((item, index) => (
        <li className="card--Requisitos__li" key={index}>
          <img className="iconsCard" src={item.icon} alt={item.title} />
          <p className="card--Requisitos__title">{item.title}</p>
        </li>
      ))}
    </ul>
  );
};

export default CardRequisitos;
