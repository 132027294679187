import React from "react";
import "../SobreNos/styles.css";
import AnimationSection from "../../Animations/AnimationComponent";

const SectionSobreNos = () => {
  return (
    <section className="container-section3" id="sobre-nos">
      <AnimationSection>
        <div className="container-text">
          <div className="div-titles">
            <h2 className="title-1">Somos a Círculo, uma consultoria de</h2>
            <h2 className="title-2">CRM & MARTECH</h2>
            <h2 className="title-3">
              e parceira oficial Multicloud Salesforce
            </h2>
          </div>
          <div className="div-paragrafo">
            <p className="paragrafo">
              Nosso foco é apoiar organizações a
              <span>
                {" "}
                melhorar o relacionamento com seus clientes e otimizar seus
                processos de vendas, marketing e atendimento ao cliente.
              </span>
                Com experiência em mais de 11 setores diferentes e empresas de
              todos os portes, contribuímos com uma abordagem personalizada, nos
              adaptando às necessidades específicas de cada cliente. Nossa
              essência é
              <span>
                Criar relações verdadeiras, inspiradas nas conexões que
                valorizamos no dia a dia.
              </span>{" "}
              Unimos marketing, vendas, atendimento e sucesso do cliente em uma
              abordagem 360°, impulsionada por dados e tecnologia.
            </p>
          </div>
        </div>
      </AnimationSection>
      <AnimationSection>
        <div className="container-cards-valores" id="propositos">
          <div className="div-valor2">
            <h3>CRIAMOS CONEXÕES</h3>
            <h2>DURADOURAS</h2>
          </div>
          <div className="div-valor1">
            <h3>
              CONSTRUÍMOS <br /> RELACIONAMENTOS
            </h3>
            <h2>VERDADEIROS</h2>
          </div>
          <div className="div-valor3">
            <h3>GERAMOS</h3>
            <h2>RESULTADOS</h2>
          </div>
        </div>
      </AnimationSection>
    </section>
  );
};

export default SectionSobreNos;
