import React from "react";
import "../Programa/styles.css";
import AnimationSection from "../../Animations/AnimationComponent";
const SectionPrograma = () => {
  return (
    <section className="section--programa" id="programa">
      <AnimationSection>
        <div className="section--programa__title">
          <h2>
            O Programa de Estágio 2024 é a oportunidade perfeita para estudantes
            que são fascinados por aprendizado e inovação.
          </h2>
        </div>
      </AnimationSection>
      <AnimationSection>
        <div className="section--programa__paragrafo">
          <p>
            Procuramos estagiários motivados e curiosos, prontos para encarar
            desafios e contribuir com suas ideias. No programa, você trabalha em
            projetos reais, ganha experiência prática, aprende de perto com a
            liderança e desenvolve suas habilidades com o suporte do time. Além
            disso, temos um ambiente super colaborativo e inclusivo, onde o
            crescimento pessoal e profissional andam lado a lado.
            <br />
            <br />
            Venha fazer parte de uma equipe que valoriza conexões duradouras e
            está sempre em busca de novas maneiras de fazer a diferença no mundo
            do CRM.
          </p>
        </div>
      </AnimationSection>
      <AnimationSection>
        <a
          className="a--buttonCta2 "
          target="_blank"
          href="https://forms.gle/6rkvf4ZGPyKq3dME6"
        >
          <button>Inscreva-se</button>
        </a>
      </AnimationSection>
    </section>
  );
};

export default SectionPrograma;
