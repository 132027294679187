import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";
import "../accordeon/accordeon.css";
import AnimationSection from "../Animations/AnimationComponent";

const Accordeon2 = () => {
  const items = [
    {
      title: "Tecnologia & Salesforce",
      content:
        "Na área de Tecnologia & Salesforce, você está imerso em soluções de CRM e tecnologia multicloud. Tem a oportunidade de desenvolver habilidades técnicas essenciais enquanto trabalha e acompanha projetos de integrações, implementações de jornadas e segmentações de usuários.",
      icon: "/icon1.png",
    },

    {
      title: "Gestão de Projetos",
      content:
        "Oferece a oportunidade de acompanhar iniciativas estratégicas desde o planejamento até a execução. Você aprende a coordenar equipes, gerenciar recursos e garantir a entrega de projetos dentro do prazo, desenvolvendo habilidades essenciais para sua carreira em gerenciamento de projetos.",
      icon: "/icon2.png",
    },
    {
      title: "Vendas",
      content:
        "Você aprende mais sobre o mundo do CRM e Salesforce, participando ativamente do ciclo de vendas e atuando junto com o time comercial para manter atualizadas as ferramentas utilizadas. Nessa área, você contribui diretamente para o crescimento e sucesso da empresa.",
      icon: "/icon3.png",
    },
    {
      title: "Dados & BI",
      content:
        "Você explora o poder dos dados para tomar decisões estratégicas junto com o time. Aprende a coletar, analisar e interpretar dados para fornecer insights valiosos que impulsionam o desempenho dos clientes. Essa é a área ideal para quem gosta de explorar padrões e tendências através de análises detalhadas.",
      icon: "/icon4.png",
    },

    {
      title: "Marketing & Comunicação",
      content:
        "Você acompanha todo o processo de criação de campanhas publicitárias, gerencia redes sociais, organiza eventos e desenvolve estratégias de endomarketing. Com foco em fortalecer a marca e engajar tanto o público externo quanto os colaboradores internos, você desenvolve habilidades essenciais em comunicação e marketing. Junto com o time, você analisa métricas de desempenho e ajusta abordagens para otimizar os resultados.",
      icon: "/icon5.png",
    },
    {
      title: "Planejamento & Estratégia",
      content:
        "Nessa área, você, junto com o time, contribui para o desenvolvimento e execução de planos estratégicos. Além disso, apoia no desenvolvimento de relatórios de resultados e apresenta recomendações estratégicas e táticas para os clientes. Aprende a analisar o mercado, definir objetivos claros e desenvolver planos de ação.",
      icon: "/icon6.png",
    },
  ];

  return (
    <ul className="ui styled accordion">
      {items.map((item, index) => (
        <Accordion
          key={item.index}
          /*           defaultIndex={[index]}
           */ allowMultiple
          display={"flex"}
          flexDirection={"row"}
          border={"none"}
          justifyContent={"center"}
          alignItems={"flex-start"}
          width={"100%"}
          maxW={"900px"}
          className=""
        >
          <AnimationSection>
            <AccordionItem
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"flex-start"}
              width={"100%"}
              maxW={"900px"}
              border={"none"}
              className="title"
            >
              <AccordionButton
                gap={"2px"}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                fontSize={{
                  base: "14px",
                  md: "14.5px",
                  lg: "16.82px",
                  xl: "18px",
                }}
              >
                <Box w={"40px"}>
                  <img
                    width={"40px"}
                    src={item.icon}
                    alt=""
                    style={{
                      width: "40px",
                    }}
                  />
                </Box>
                {item.title}
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>{item.content}</AccordionPanel>
            </AccordionItem>
          </AnimationSection>
        </Accordion>
      ))}
    </ul>
  );
};

export default Accordeon2;
