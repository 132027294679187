import React from "react";
import "../socialsIcons/styles.css";
const SocialIcons = () => {
  return (
    <div className="container--socialIcons__div">
      <a
        className="container--socielIcons__a"
        href="https://www.linkedin.com/company/c%C3%ADrculo-crm/"
      >
        <img src="/Linkedin icon.png" alt="" />
      </a>
      <a
        className="container--socielIcons__a"
        href="https://www.instagram.com/circulocrm/"
      >
        <img src="/Instagram icon.png" alt="" />
      </a>
      <a
        className="container--socielIcons__a"
        href="https://www.facebook.com/circulocrm/"
      >
        <img src="/Facebook icon.png" alt="" />
      </a>
      <a
        className="container--socielIcons__a"
        href="mailto:contato@circulocrm.com.br"
      >
        <img src="/Email icon.png" alt="" />
      </a>
    </div>
  );
};

export default SocialIcons;
