import React from "react";
import "./sectionPrincipal.css";
import AnimationSection from "../../Animations/AnimationComponent";

const SectionPrincipal = () => {
  return (
    <AnimationSection>
      <section className="section--principal" id="#/">
        <div className="container--text">
          <h2>Programa</h2>
          <h2 className="text-title-secundary">de Estágio 2024</h2>
          <p className="subtitle">Faça parte do nosso Círculo!</p>
          <a
            className="a--buttonCta2"
            href="https://forms.gle/6rkvf4ZGPyKq3dME6"
          >
            Saiba mais
          </a>
        </div>
      </section>
    </AnimationSection>
  );
};

export default SectionPrincipal;
