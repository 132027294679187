import React from "react";
import Accordion from "../../accordeon/accordeon";
import "../Programa/sectionAreaPrograma.css";
import Accordeon2 from "../../accordeon/accordeon2";
const AreasDoPrograma = () => {
  return (
    <section className="section--areaPrograma">
      <h2 className="section--areaPrograma__h2">Áreas do Programa</h2>
      <div className="section--areaPograma__div">
        {/* <Accordion /> */}
        <Accordeon2 />
      </div>
    </section>
  );
};

export default AreasDoPrograma;
