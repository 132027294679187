import React from "react";
import "../Inclusao/styles.css";
import AnimationSection from "../../Animations/AnimationComponent";
const SectionInclusao = () => {
  return (
    <section id="inclusao" className="section4-diversidade">
      <div className="container-diversidade container--diversidade__text">
        <h2 className="diversidade-title-1">
          Abraçamos a diversidade como um fator fundamental de nossa cultura.{" "}
        </h2>
        <p>
          Nosso compromisso é criar um ambiente de trabalho inclusivo, onde{" "}
          <span>
            cada indivíduo é valorizado independentemente de sua origem,
            identidade ou características pessoais.
          </span>{" "}
          Nossa política se baseia em igualdade de oportunidades, respeito à
          individualidade, promoção de um ambiente colaborativo e comunicação
          aberta.
          <span>
            {" "}
            Rejeitamos qualquer forma de discriminação, preconceito ou assédio.
          </span>
        </p>
        <div className="container__img"></div>
      </div>
    </section>
  );
};

export default SectionInclusao;
