import React, { useEffect, useState } from "react";
import { GiHamburgerMenu, GiCardboardBoxClosed } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";
import "./styles.css";

const Header = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [color, setColor] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleScroll = () => {
    setColor(window.scrollY);
  };

  const getScreen = () => {
    if (window.innerWidth < 1276) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    getScreen();
    window.addEventListener("resize", getScreen);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("resize", getScreen);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToAction = (event, id) => {
    event.preventDefault();
    setMenuOpen(false);
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
  };

  const bluer = `
    -webkit-backdrop-filter: blur(10px); 
  backdrop-filter: blur(10px);
  -webkit-filter: blur(10px); 
  filter: blur(10px);  
  `;

  return (
    <div
      id="header"
      style={{
        color: "Menu",
        backgroundColor: color > 0 ? "rgba(255, 255, 255, 0.1)" : "#1E1E1E",
        backdropFilter: color > 0 ? "blur(6px)" : "none",
        WebkitBackdropFilter: color > 0 ? "blur(6px)" : "none",
      }}
    >
      <a href="#" style={{ margin: 2 }}>
        <div style={{ width: "100%", textAlign: "center" }}>
          <img
            src="/logo.png"
            alt="Logomarca da círculo crm"
            style={{ zIndex: 999, maxWidth: "150px" }}
          />
        </div>
      </a>
      <div
        style={{
          width: "74%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        {isMobile ? (
          <>
            <button
              onClick={() => setMenuOpen(!menuOpen)}
              style={{
                background: "none",
                border: "none",
                color: "black",
                position: "absolute",
                right: "20px",
                zIndex: 2000,
              }}
            >
              {menuOpen ? (
                <IoMdClose size={40} color="white" width={43} height={43} />
              ) : (
                <GiHamburgerMenu
                  size={40}
                  color="white"
                  width={43}
                  height={43}
                />
              )}
            </button>
            <div
              className="menu-list"
              style={{
                transform: menuOpen ? "translateY(0)" : "translateY(-150%)",
              }}
            >
              <div className="menu-item">
                <a
                  onClick={(e) => scrollToAction(e, "sobre-nos")}
                  className="link-cta"
                >
                  <p>Sobre nós</p>
                </a>
              </div>
              <div className="menu-item">
                <a
                  onClick={(e) => scrollToAction(e, "propositos")}
                  className="link-cta"
                >
                  <p>Propósitos</p>
                </a>
              </div>
              <div className="menu-item">
                <a
                  onClick={(e) => scrollToAction(e, "inclusao")}
                  className="link-cta"
                >
                  <p>Inclusão</p>
                </a>
              </div>
              <div className="menu-item">
                <a
                  onClick={(e) => scrollToAction(e, "programa")}
                  className="link-cta"
                >
                  <p>O programa</p>
                </a>
              </div>
              <div className="menu-item">
                <a
                  onClick={(e) => scrollToAction(e, "etapas-do-processo")}
                  className="link-cta"
                >
                  <p>Etapas do processo</p>
                </a>
              </div>
              <div className="menu-item">
                <a
                  onClick={(e) => scrollToAction(e, "beneficios")}
                  className="link-cta"
                >
                  <p>Pré-requisitos</p>
                </a>
              </div>
              <div className="menu-item">
                <a
                  onClick={(e) => scrollToAction(e, "beneficios")}
                  className="link-cta"
                >
                  <p>Benefícios</p>
                </a>
              </div>
              <div className="menu-item">
                <a
                  className="link-cta"
                  href="https://forms.gle/6rkvf4ZGPyKq3dME6"
                >
                  <button className="buttonCtaMobile">Inscreva-se</button>
                </a>
              </div>
            </div>
          </>
        ) : (
          <div
            className="container-menu"
            style={{ display: "flex", gap: "0px", alignItems: "center" }}
          >
            <a
              onClick={(e) => scrollToAction(e, "sobre-nos")}
              className="link-cta"
            >
              <p>Sobre nós</p>
            </a>
            <a
              onClick={(e) => scrollToAction(e, "propositos")}
              className="link-cta"
            >
              <p>Propósitos</p>
            </a>
            <a
              onClick={(e) => scrollToAction(e, "inclusao")}
              className="link-cta"
            >
              <p>Inclusão</p>
            </a>
            <a
              onClick={(e) => scrollToAction(e, "programa")}
              className="link-cta"
            >
              <p>O programa</p>
            </a>
            <a
              onClick={(e) => scrollToAction(e, "etapas-do-processo")}
              className="link-cta"
            >
              <p>Etapas do processo</p>
            </a>
            <a
              onClick={(e) => scrollToAction(e, "beneficios")}
              className="link-cta"
            >
              <p>Pré-requisitos</p>
            </a>
            <a
              onClick={(e) => scrollToAction(e, "beneficios")}
              className="link-cta"
            >
              <p>Benefícios</p>
            </a>
            <a href="https://forms.gle/6rkvf4ZGPyKq3dME6" className="link-cta">
              <button className="buttonCta">Inscreva-se</button>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
