import React from "react";
import { Helmet } from "react-helmet";

const MetaTags = () => (
  <Helmet>
    <title>Circulo | Programa de Estágio 2024</title>
    <meta name="description" content="Circulo | Programa de Estágio 2024" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta charSet="utf-8" />
    <meta name="author" content="Círculo Consultoria de CRM & Martech" />
    <meta
      name="keywords"
      content="estágio, programa de estagio 2024, empregos, círculo crm, salesforce, "
    />

    {/* Open Graph / Facebook */}
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://www.circulocrm.com.br" />
    <meta property="og:title" content="Circulo | Programa de Estágio 2024" />
    <meta
      property="og:description"
      content="O Programa de Estágio 2024 é a oportunidade perfeita para estudantes que são fascinados por aprendizado e inovação."
    />
    <meta
      property="og:image"
      content="https://www.circulocrm.com.br/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FLogo.d9fd33c6.png&w=256&q=75"
    />

    {/* Twitter */}
    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content="https://www.circulocrm.com.br" />
    <meta
      property="twitter:title"
      content="Circulo | Programa de Estágio 2024"
    />
    <meta
      property="twitter:description"
      content="O Programa de Estágio 2024 é a oportunidade perfeita para estudantes que são fascinados por aprendizado e inovação."
    />
    <meta
      property="twitter:image"
      content="hhttps://www.circulocrm.com.br/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FLogo.d9fd33c6.png&w=256&q=75"
    />
    <link
      rel="icon"
      href="https://www.circulocrm.com.br/favicon.ico"
      type="image/x-icon"
    />
    <link
      rel="shortcut icon"
      href="https://www.circulocrm.com.br/favicon.ico"
      type="image/x-icon"
    />
    <link
      rel="icon"
      href="https://www.circulocrm.com.br/favicon.ico"
      type="image/x-icon"
      sizes="38x39"
    />
  </Helmet>
);

export default MetaTags;
