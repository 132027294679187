import React from "react";
import "../Inclusao/styles.css";
import AnimationSection from "../../Animations/AnimationComponent";
const SectionInclusaoFull = () => {
  return (
    <section id="inclusao" className="section4-diversidade">
      <div className="container-diversidade ">
        <AnimationSection>
          <div className="container-imagem-diversidade"></div>
        </AnimationSection>
        <AnimationSection>
          <div className="container-text-diversidade container--diversidade__text">
            <h2 className="diversidade-title-1">
              Abraçamos a diversidade como um fator fundamental de nossa
              cultura.{" "}
            </h2>
            <p className="div-paragrafo-1">
              Nosso compromisso é criar um ambiente de trabalho inclusivo,{" "}
              <span>
                Onde, cada indivíduo é valorizado independentemente de sua
                origem, identidade ou características pessoais.
              </span>{" "}
              Nossa política se baseia em igualdade de oportunidades, respeito à
              individualidade, promoção de um ambiente colaborativo e
              comunicação aberta.
              <span>
                Rejeitamos qualquer forma de discriminação, preconceito ou
                assédio.
              </span>
            </p>
          </div>
        </AnimationSection>
      </div>
    </section>
  );
};

export default SectionInclusaoFull;
