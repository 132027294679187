import React from "react";
import "../Programa/sectionPassos.css";
import AnimationSection from "../../Animations/AnimationComponent";
const PassosPrograma = () => {
  return (
    <section className="container-cards">
      <AnimationSection>
        <h2 className="title-cards ">Experiência do programa</h2>
        <div className="div-image-fluxo"></div>
      </AnimationSection>
      <AnimationSection>
        <div className="div-image-fluxo2" id="etapas-do-processo"></div>
      </AnimationSection>
    </section>
  );
};

export default PassosPrograma;
